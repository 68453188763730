import React from 'react'
import Button from '../components/common/button'
import cn from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as styles from './hero.module.css'
import { button, primary } from '../components/common/button.module.css'

const Hero = ({ image, title, content }) => (
  <div className={styles.hero}>
    {image && (
      <GatsbyImage className={styles.image} alt={title} image={image} />
    )}
    <div className={styles.details}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>Hello!</h1>
        <div className={styles.socialWrapper}>
          <a
            className={styles.socialLink}
            href="https://dribbble.com/amucinom"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              className={styles.dribbble}
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 22a10 10 0 1110-10 10 10 0 01-10 10zm1.36-7.55a10.54 10.54 0 00-5.84 3.73l-.13.17.18.12A7.81 7.81 0 0012 19.86a8 8 0 00.85 0H13v-.12a11.7 11.7 0 00.65-3.85c0-.41 0-.81-.06-1.2v-.23zm2.47-.3h-.5v.22a13.93 13.93 0 01.08 1.43 13.72 13.72 0 01-.35 3l-.09.41.38-.18A7.87 7.87 0 0019.27 15l.08-.2-.21-.07a10.5 10.5 0 00-3.31-.58zM4.18 11.26V12a7.87 7.87 0 001.78 5l.16.19.16-.2A12.16 12.16 0 0113 12.73h.21l-.06-.21a11.57 11.57 0 00-.52-1.52l-.07-.17-.18.06a13.58 13.58 0 01-4.2.68 13.61 13.61 0 01-3.73-.53L4.2 11zm11.65 1.14a12.26 12.26 0 013.72.58l.24.08v-.25a7.8 7.8 0 00-1.47-5.42l-.14-.2-.18.17a13.42 13.42 0 01-3.66 2.72l-.17.09.08.18a13.33 13.33 0 01.75 1.94v.16h.43zM7.49 5.58a7.87 7.87 0 00-2.78 3.51l-.08.21.22.06a11.85 11.85 0 003.31.47 12 12 0 003.26-.45l.28-.08-.16-.24a12.05 12.05 0 00-3.82-3.5l-.13-.06zM12 4.14a7.86 7.86 0 00-2.13.3l-.4.11.34.25a13.74 13.74 0 013.47 3.66l.1.15.16-.08a11.63 11.63 0 003.28-2.46l.18-.15-.18-.14A7.77 7.77 0 0012 4.14z"
                data-name="Dribbble 1"
              ></path>
            </svg>
          </a>
          <a
            className={styles.socialLink}
            href="https://www.linkedin.com/in/amucinom/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              className={styles.linkedin}
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M3 7h4v14H3zm20 6.11C23 9.72 22.24 7 18.12 7a4.75 4.75 0 00-3.85 2H14V7h-4v14h4v-7.21c0-1.64.51-3.22 2.67-3.22S19 12.44 19 13.9V21h4zM5 2a2 2 0 102 2 2 2 0 00-2-2z"></path>
            </svg>
          </a>
        </div>
      </div>
      {content && <p className={styles.content}>{content}</p>}
      <div className={styles.detailsButtonWrapper}>
        <Button to="/work" primary outline>
          My Work
        </Button>
        <a
          className={cn(button, primary)}
          href="mailto:amucinom@gmail.com"
          target="_blank"
          rel="noreferrer"
          primary
        >
          Let's Chat!
        </a>
      </div>
      <div className={styles.socialWrapper}>
        <a
          className={styles.socialLink}
          href="https://dribbble.com/amucinom"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            className={styles.dribbble}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 22a10 10 0 1110-10 10 10 0 01-10 10zm1.36-7.55a10.54 10.54 0 00-5.84 3.73l-.13.17.18.12A7.81 7.81 0 0012 19.86a8 8 0 00.85 0H13v-.12a11.7 11.7 0 00.65-3.85c0-.41 0-.81-.06-1.2v-.23zm2.47-.3h-.5v.22a13.93 13.93 0 01.08 1.43 13.72 13.72 0 01-.35 3l-.09.41.38-.18A7.87 7.87 0 0019.27 15l.08-.2-.21-.07a10.5 10.5 0 00-3.31-.58zM4.18 11.26V12a7.87 7.87 0 001.78 5l.16.19.16-.2A12.16 12.16 0 0113 12.73h.21l-.06-.21a11.57 11.57 0 00-.52-1.52l-.07-.17-.18.06a13.58 13.58 0 01-4.2.68 13.61 13.61 0 01-3.73-.53L4.2 11zm11.65 1.14a12.26 12.26 0 013.72.58l.24.08v-.25a7.8 7.8 0 00-1.47-5.42l-.14-.2-.18.17a13.42 13.42 0 01-3.66 2.72l-.17.09.08.18a13.33 13.33 0 01.75 1.94v.16h.43zM7.49 5.58a7.87 7.87 0 00-2.78 3.51l-.08.21.22.06a11.85 11.85 0 003.31.47 12 12 0 003.26-.45l.28-.08-.16-.24a12.05 12.05 0 00-3.82-3.5l-.13-.06zM12 4.14a7.86 7.86 0 00-2.13.3l-.4.11.34.25a13.74 13.74 0 013.47 3.66l.1.15.16-.08a11.63 11.63 0 003.28-2.46l.18-.15-.18-.14A7.77 7.77 0 0012 4.14z"
              data-name="Dribbble 1"
            ></path>
          </svg>
        </a>
        <a
          className={styles.socialLink}
          href="https://www.linkedin.com/in/amucinom/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            className={styles.linkedin}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M3 7h4v14H3zm20 6.11C23 9.72 22.24 7 18.12 7a4.75 4.75 0 00-3.85 2H14V7h-4v14h4v-7.21c0-1.64.51-3.22 2.67-3.22S19 12.44 19 13.9V21h4zM5 2a2 2 0 102 2 2 2 0 00-2-2z"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
)

export default Hero
