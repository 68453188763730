// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--25w3J";
export var image = "hero-module--image--3EwX_";
export var details = "hero-module--details--2GmK8";
export var socialWrapper = "hero-module--social-wrapper--1etoo";
export var titleWrapper = "hero-module--title-wrapper--JmAO1";
export var socialLink = "hero-module--social-link--WVNq6";
export var dribbble = "hero-module--dribbble--3aVzc";
export var linkedin = "hero-module--linkedin--19tkP";
export var title = "hero-module--title--1vq51";
export var content = "hero-module--content--vT9Ih";
export var detailsButtonWrapper = "hero-module--details-button-wrapper--3czFs";