import React from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import * as styles from './button.module.css'

const Button = ({ to, children, primary, outline, clear, disabled }) => {
  return (
    <Link
      to={to}
      className={cn({
        [styles.button]: true,
        [styles.primary]: primary,
        [styles.outline]: outline,
        [styles.clear]: clear,
      })}
      disabled={disabled}
    >
      {children}
    </Link>
  )
}

export default Button
